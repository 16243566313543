import _export from "../internals/export";
import _stringTrim from "../internals/string-trim";
import _stringTrimForced from "../internals/string-trim-forced";
var $ = _export;
var $trim = _stringTrim.trim;
var forcedStringTrimMethod = _stringTrimForced; // `String.prototype.trim` method
// https://tc39.es/ecma262/#sec-string.prototype.trim

$({
  target: "String",
  proto: true,
  forced: forcedStringTrimMethod("trim")
}, {
  trim: function trim() {
    return $trim(this);
  }
});
export default {};